.btn-outline-primary {
  border: 1px solid $primary !important;
  color: $primary !important;
  &:hover,
  &:focus,
  &:active {
    background: $primary !important;
    color: white !important;
    border: 1px solid $primary !important;
    box-shadow: none !important;
  }
}
.btn-danger {
  color: white !important;
  background-color: #e20000 !important;
  border-color: #e20000 !important;
  &:hover,
  &:focus,
  &:active {
    color: #e20000 !important;
    background-color: white !important;
    border-color: #e20000 !important;
    box-shadow: none !important;
  }
}
.btn-success {
  color: white !important;
  background-color: #00c10d !important;
  border-color: #00c10d !important;
  &:hover,
  &:focus,
  &:active {
    color: #00c10d !important;
    background-color: white !important;
    border-color: #00c10d !important;
    box-shadow: none !important;
  }
}
.btn-primary {
  color: white !important;
  background-color: $primary !important;
  border-color: $primary !important;
  &:hover,
  &:focus,
  &:active {
    color: $primary !important;
    background-color: white !important;
    border-color: $primary !important;
    box-shadow: none !important;
  }
}
.btn-black {
  color: white !important;
  background-color: black !important;
  border-color: black !important;
  &:hover,
  &:focus,
  &:active {
    color: black !important;
    background-color: white !important;
    border-color: black !important;
    box-shadow: none !important;
  }
}

.btn-secondary {
  color: white !important;
  background-color: $gray !important;
  border-color: $gray !important;
  &:hover,
  &:focus,
  &:active {
    color: $gray !important;
    background-color: white !important;
    border-color: $gray !important;
    box-shadow: none !important;
  }
}
.sidebar .nav li .nav-link {
  display: inline-flex;
  align-items: center;
  width: 100%;
  margin: 0px !important;
  i {
    font-size: 22px;
    width: 30px;
  }
}
.revenue {
  path {
    fill: $primary;
  }
}
.custom-control-input:checked ~ .custom-control-label::before {
  color: white;
  border-color: $primary;
  background-color: $primary;
}
.text-primary {
  color: $primary !important;
}
.badge-primary {
  background: $primary;
}
.badge-secondary {
  background: #a5a5a5;
}
.text-green {
  color: #3fc227 !important;
}

.text-gray {
  color: $text-gray !important;
}
.secondary-gray {
  color: $gray;
}
.text-secondary {
  color: $text-secondary !important;
}
.text-dark-blue {
  color: $black-zero;
}
.thumbnail-round {
  width: 50px;
  height: 50px;
  border-radius: 100px;
  overflow: hidden;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}
.thumbnail-round-md {
  width: 80px;
  height: 80px;
  border-radius: 100px;
  overflow: hidden;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}
.thumbnail-round-lg {
  width: 120px;
  height: 120px;
  border-radius: 100px;
  overflow: hidden;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}
.thumbnail-box {
  width: 36px;
  height: 36px;
  overflow: hidden;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}
.sidebar {
  .logo {
    a {
      margin: 0 auto;
    }
  }
  .logo-img {
    img {
      max-width: 140px;
      max-height: 42px;
    }
  }
  .nav-link {
    text-transform: none !important;
    p {
      text-transform: none !important;
    }
  }
}
.sidebar:before,
body > .navbar-collapse:before {
  opacity: 1;
  background: #140400;
}
.sidebar[data-color="black"]:after {
  display: none;
}
.full-page .full-page-background {
  position: absolute;
  z-index: 1;
  height: 100%;
  width: 100%;
  display: block;
  top: 0;
  left: 0;
  background-size: cover;
  background-position: center center;
}
.full-page:before,
.full-page:after {
  display: block;
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 2;
}
.full-page > .content {
  min-height: calc(100vh - 70px);
  z-index: 4;
  position: relative;
}
.btn-wd {
  width: 210px;
}
.btn-wd-s {
  width: 140px;
}
.opacity {
  opacity: 0.4;
}
.bg-dashboard {
  background: url(./../img/dashboard_bg.jpg);
  height: 100%;
  width: 100%;
  object-fit: cover;
}
.btn-primary {
  // background-color: $primary-bg !important;
  // color: white !important;
}

.form-control {
  height: 48px;
  border: 1px solid $border-light;
  color: $placeholder-light;

  &::placeholder {
    color: $placeholder-light;
  }
}
.table td,
.table th {
  padding: 8px 14px !important;
  border-top: 1px solid #d2d8df;
}
.modal-dialog {
  margin: 0px 0px 0px auto !important;
  top: 0;
  transform: translate(0, 0%) !important;
  @media (min-width: 767px) {
    max-width: 600px;
  }
  @media (max-width: 700px) {
    max-width: 350px !important;
  }
  .modal-content {
    min-height: 100vh;
    border-radius: 0px;
    border: none;
  }
}
.form-check .form-check-label {
  padding-left: 5px;
}
.rounded-right {
  border-radius: 0px 4px 4px 0px !important;
}
.bg-success {
  background-color: #00c10d !important;
  min-width: 45px;
}
.bg-primary {
  background-color: $primary !important;
  width: 45px;
}
.badge-success {
  background: #06bf00;
}
.badge-danger {
  background: #e40000;
}
.product-img {
  width: 100px;
  height: 100px;
}
.black-one {
  color: $black-one;
}
.common-card {
  @media (max-width: 1500px) {
    h2 {
      font-size: 21px !important;
    }
    h6 {
      font-size: 11px !important;
    }
  }
}

.word_break {
  word-break: break-all;
}