@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");
body {
  font-family: "Roboto", sans-serif;
}
.menu_active p,
.menu_active i {
  color: #f13903ad;
}

::-webkit-scrollbar {
  width: 4px;
  height: 4px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #f13903ad;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #f13903ad;
}

.admin_dropdown button {
  border: none !important;
  margin: 0 !important;
  background-color: transparent !important;
  color: black !important;
  border: none !important;
}
.admin_dropdown button::after {
  margin-top: 0 !important;
}
.close_bar {
  width: 60px !important;
}
.full_width {
  width: calc(100% - 60px) !important;
}
.sidebar,
.main-panel {
  transition: 0.5s;
  overflow-x: hidden;
}

/* sleleton elements */
.skeleton {
  color: transparent !important;
  background: linear-gradient(
    90deg,
    hsl(210, 15%, 88%),
    hsl(210, 15%, 95%),
    hsl(210, 15%, 88%)
  );
  background-size: 200%;
  animation: loader 1s infinite reverse;
}
h2.skeleton {
  width: 50%;
  height: 1em;
}
small.skeleton {
  height: 1rem;
  width: 4rem;
}
p.skeleton {
  height: 3em;
}
.width_fit {
  width: fit-content;
}
.opacity-0 {
  opacity: 0;
}

/* skeleton animation */
@keyframes loader {
  from {
    background-position: -100% 0;
  }
  to {
    background-position: 100% 0;
  }
}
.car-card-img {
  overflow: hidden;
  height: 245px;
  border-radius: 5px;
}

.car-card-img img {
  height: 100%;
  object-fit: cover;
}
.addphoto .rounded {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  object-fit: cover;
  width: 100%;
  cursor: pointer;
}
.addphoto .mark-sec {
  left: 0;
}

.dotted_border {
  border-radius: 75%;
  overflow: hidden;
  width: 140px;
  height: 140px;
  border: 1px dotted;
  transform: translate(-50%, 0);
  left: 50%;
}
.show_false {
  position: absolute;
  top: 50%;
  right: 3%;
  transform: translate(0, -50%);
  background-color: transparent;
  border: navajowhite;
}
.w-0 {
  width: 0 !important;
}
.h-0 {
  height: 0 !important;
}
.ant-message {
  z-index: 1090;
}
.sort_bt {
  display: flex;
  flex-direction: column;
  margin-left: 10px;
}
.ant-switch-checked {
  background-color: #f13903ad;
}
.ant-btn-primary {
  background-color: #f13903ad;
  border: #f13903ad;
}
.table > thead > tr > th {
  position: relative !important;
}
.uploading {
  filter: blur(1.5px);
}

.ant-pagination-item-active {
  border-color: #f13903ad;
}
.ant-pagination-item:hover {
  border-color: #f13903ad;
}
.pointer {
  cursor: pointer;
}
.pointer:hover {
  transform: scale(1.1);
}
.single_f {
  width: 100%;
}
.thumbnail-box .ant-image {
  object-fit: cover;
  width: 100%;
  height: 100%;
}
.cancelled {
  color: #f13903 !important;
  font-weight: bold;
}

.cancelled span {
  background-color: #f13903 !important;
  width: 10px;
  height: 10px;
  display: inline-block;
  margin-right: 6px;
  border-radius: 50px;
}

.on-the-way {
  color: #00cbffc9 !important;
  font-weight: bold;
}

.on-the-way span {
  background-color: #00cbffc9 !important;
  width: 10px;
  height: 10px;
  display: inline-block;
  margin-right: 6px;
  border-radius: 50px;
}
.ordered {
  color: #d4b400de;
  font-weight: bold;
}

.ordered span {
  background-color: #d4b400de !important;
  width: 10px;
  height: 10px;
  display: inline-block;
  margin-right: 6px;
  border-radius: 50px;
}
.delivered {
  color: #00b6009c !important;
  font-weight: bold;
}

.delivered span {
  background-color: #00b6009c !important;
  width: 10px;
  height: 10px;
  display: inline-block;
  margin-right: 6px;
  border-radius: 50px;
}
.addphoto .mark-sec {
  background: #f0f8ffbf !important;
  border: 1px solid #00000033 !important;
}
.border_active {
  border: 1px solid #dc3545 !important;
}
.warning_red {
  background-color: red !important;
  width: 100% !important;
}

.ant-pagination-item {
  border-radius: 50%;
  border: none;
}

.ant-pagination-prev button {
  border: none !important;
  background: transparent !important;
  font-weight: bold;
}
.ant-pagination-prev button:after {
  content: "Prev";
  margin-left: 10px;
}

.ant-pagination-next button {
  border: none !important;
  background: transparent !important;
  font-weight: bold;
}
.ant-pagination-next button:before {
  content: "Next";
  margin-right: 10px;
}
.ant-pagination-item-active {
  background-color: #f13903 !important;
  color: #ffffff !important;
}
.ant-pagination {
  float: right;
}
.thumbnail-round-lg span {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.product-img span {
  height: 100%;
  object-fit: cover;
}
.product-img span img {
  height: 100%;
  object-fit: cover;
}

.login_logo {
  max-width: 134px;
  margin-bottom: 3vh;
}
.ride_status {
  font-weight: bold;
}

.ride_status span {
  width: 10px;
  height: 10px;
  display: inline-block;
  margin-right: 6px;
  border-radius: 50px;
}
.for_transition {
  transition: transform 0.2s;
}
.navbar .navbar-nav .nav-item .nav-link:hover {
  color: #f13903;
}
.empty_items {
  height: 60vh;
}

.v-detail-overlay {
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  opacity: 0;
}
.ReactGridGallery_tile-viewport {
  height: 338px !important;
  width: 300px !important;
}
.ant-radio-checked::after {
  border: 1px solid #f13903;
}
.ant-radio-checked .ant-radio-inner {
  border: 1px solid #f13903;
}
.ant-radio-wrapper:hover .ant-radio,
.ant-radio:hover .ant-radio-inner,
.ant-radio-input:focus + .ant-radio-innera {
  border-color: #f13903;
}
.ant-radio-inner::after {
  background-color: #f13903;
}
.ant-radio-input:focus + .ant-radio-inner {
  box-shadow: 0 0 0 3px #f13a0309;
}
.ant-radio-wrapper:hover .ant-radio,
.ant-radio:hover .ant-radio-inner,
.ant-radio-input:focus + .ant-radio-inner {
  border-color: #f13903;
}
.primary_btn:hover {
  border: 1px solid #f13903;
}
.active .ant-timeline-item-head.ant-timeline-item-head-red {
  background-color: #ff4d4f;
  border-color: #ff4d4f;
}

.active .ant-timeline-item-tail {
  border-left: 2px solid #ff4d4f;
}
.ant-timeline-item-head.ant-timeline-item-head-red {
  background-color: #cbcbcb;
  border-color: #cbcbcb;
}

.ant-timeline-item-tail {
  border-left: 2px solid #cbcbcb;
}
.car-card-img span {
  height: 100%;
  object-fit: cover;
}
.border_nun {
  border: 1px solid #cbcbcb;
}
::before {
}

.ant-picker-cell-in-view.ant-picker-cell-today .ant-picker-cell-inner::before {
  border: 1px solid #f13903;
}

.ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner,
.ant-picker-cell-in-view.ant-picker-cell-range-start .ant-picker-cell-inner,
.ant-picker-cell-in-view.ant-picker-cell-range-end .ant-picker-cell-inner {
  background-color: #f13903;
}

.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):hover {
  color: #f13903;
  border-color: #f13903;
}

label.ant-radio-button-wrapper.ant-radio-button-wrapper-checked {
  border-right-color: #f13903;
}

.ant-radio-button-wrapper-checked:not([class*=" ant-radio-button-wrapper-disabled"]).ant-radio-button-wrapper:first-child {
  border-right-color: #f13903;
}

.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):first-child {
  border-color: #f13903;
}

label.ant-radio-button-wrapper.ant-radio-button-wrapper-checked {
}

.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
  color: #f13903;
}
.ScheduleRideModal .modal-dialog {
  max-width: 520px;
}

.orange_btn {
  background-color: #f13903;
  border: 1px solid #f13903;
  color: #ffffff;
}
.white_btn {
  color: #f13903;

  background-color: #ffffff;
  border: 1px solid #f13903 !important;
}
.reschedule .ant-timeline-item-tail {
  border-left: 2px dotted #ff4d4f;
}

.reschedule .starting .ant-timeline-item-head.ant-timeline-item-head-red {
  background-color: white;
}

.dollar {
  font-size: 20px;
  font-weight: bold;
  background-color: #e8fee4;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
}
.dollar span {
  font-size: 14px;
  background-color: #3fc227;
  color: #ffffff;
  padding: 3px 9px;
  border-radius: 50%;
}
.detail_image {
  height: 20vh;
  object-fit: cover;
}
input.invalid {
  outline-color: red;
  /* also need animation and -moz-animation */
  -webkit-animation: shake 0.5s linear;
}
/* also need keyframes and -moz-keyframes */
@-webkit-keyframes shake {
  8%,
  41% {
    -webkit-transform: translateX(-10px);
  }
  25%,
  58% {
    -webkit-transform: translateX(10px);
  }
  75% {
    -webkit-transform: translateX(-5px);
  }
  92% {
    -webkit-transform: translateX(5px);
  }
  0%,
  100% {
    -webkit-transform: translateX(0);
  }
}

.mapSkeleton {
  width: 100%;
  min-height: 30vh;
}
.btn.focus,
.btn:focus {
  box-shadow: none;
}
.customer_detail_settings {
  position: absolute;
  top: 10px;
  right: 0;
  width: fit-content;
  z-index: 1;
}
.display_anim {
  max-height: 0;
  transition: max-height 1ms ease-out;
  overflow: hidden;
}
.loading_tire {
  max-width: 50px;
  -webkit-animation: spin 2s linear infinite;
  animation: spin 2s linear infinite;
}
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.notification_main {
  max-height: 60vh;
  min-height: 200px;
  overflow-x: hidden;
  overflow-y: auto;
  margin-right: -16px;
}
.ant-empty-description {
  margin-right: 0px;
}
.text-danger span {
  background-color: #f13903 !important;
}
.notification_title {
  font-size: 14px;
  font-weight: bold;
  color: black;
}
.notification_title span {
  color: #f13903;
  font-size: 12px;
  opacity: 0.5;
}
.active_dote {
  background-color: #f13903;
  border-radius: 50%;
  width: 7px;
  height: 7px;
  margin-bottom: 2px;
  margin-right: 5px;
  display: inline-flex;
}
.ant-card-meta-detail > div:not(:last-child) {
  margin-bottom: 0;
}
.ant-card-meta-description {
  font-size: 14px;
}
.notification_main_outer .ant-popover-inner-content {
  padding-right: 0;
}
.notify_num {
  position: absolute;
  top: -2px;
  right: -10px;
  background-color: #f13903;
  border-radius: 50%;
  padding: 10px;
  overflow: hidden;
  color: #fff;
  line-height: 0;
  letter-spacing: 0;
  font-size: 12px;
  font-weight: bold;
  width: 11px;
  display: flex;
  justify-content: center;
}
.deleting_gif {
  width: 100%;
  height: 50vh;
}
.special_main input {
  color: #000000 !important;
}
.special_main input::placeholder {
  color: rgb(197, 197, 197);
}
.special_main select {
  color: rgb(197, 197, 197);
}
.special_main option {
  color: rgb(0, 0, 0);
}
.password_conform_main input[type="submit"] {
  padding: 15px 100px!important;
}

.password_conform_main h4 {
  font-size: 18px!important;
  font-weight: bolder!important;
  line-height: 1.5!important;
}
.promo-code{
  text-transform: uppercase;

}
.promo-code::placeholder {
  text-transform: capitalize;

}
.vehicle_placeholder input::placeholder {
color: #77777774;
}

@media (min-width:1200px) and (max-width:1500px) {
  h3 {
      font-size: 1.5vw;
  }
}
.min_fit{
  min-width: fit-content;
}