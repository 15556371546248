
.border-right-dashed{
    border-right: 1px dashed #D5D5D5;
    @media screen and (max-width:768px){
        border-right: none ; 
    }
}
.border-left-dashed{
    border-left: 1px dashed #D5D5D5;
}
.revup-table{
    h5{
        font-size: 14px;
       
    }
    p{
        font-size: 14px;
    }
    th{
        font-size: 14px !important;
        font-weight: $font-weight-bold !important;
        color: $black-zero !important;
        white-space: nowrap;
        text-transform: capitalize !important;
        padding: 16px 14px !important;
        border-top: 1px solid #D2D8DF;
    }
    td{
        font-size: $font-size-base !important;
        white-space: nowrap;
        border-right: 1px solid #A9C0DC;
        &:last-child{
            border-right: none;
        }
    }
    tr:hover {
        background-color: #f5f5f5 !important;
    }
    .text-active{
        color: #00C10D !important;
        font-weight: bold;
        span{
            background-color: #00C10D;
            width: 10px;
            height: 10px;
            display: inline-block;
            margin-right: 6px;
            border-radius: 50px;
        }
    }
    .text-info{
        color: #009DC5 !important;
        font-weight: bold;
        span{
            background-color: #009DC5;
            width: 10px;
            height: 10px;
            display: inline-block;
            margin-right: 6px;
            border-radius: 50px;
        }
    }
    .text-w{
        color: #EBC400 !important;
        font-weight: bold;
        span{
            background-color: #EBC400;
            width: 10px;
            height: 10px;
            display: inline-block;
            margin-right: 6px;
            border-radius: 50px;
        }
    }
    .text-completed{
        color: #E40000 !important;
        font-weight: bold;
        height: 54px;
        span{
            background-color: #E40000;
            width: 10px;
            height: 10px;
            display: inline-block;
            margin-right: 6px;
            border-radius: 50px;
        }
    }
    .text-primary-s{
        color: $primary!important;
        font-weight: bold;
        span{
            background-color: $primary !important;
            width: 10px;
            height: 10px;
            display: inline-block;
            margin-right: 6px;
            border-radius: 50px;
        }
    }
    .text-danger{
        color:  #E20000 !important;
    }
}
.text-completed{
    color: #EE0000 !important;
    font-weight: bold;
    span{
        background-color: #EE0000;
        width: 10px;
        height: 10px;
        display: inline-block;
        margin-right: 6px;
        border-radius: 50px;
    }
}
.text-active{
    color: #00C10D !important;
    font-weight: bold;
    span{
        background-color: #00C10D;
        width: 10px;
        height: 10px;
        display: inline-block;
        margin-right: 6px;
        border-radius: 50px;
    }
}

.form-default{
    label{
        color: #000000;
        font-weight: bold;
        font-size: 16px;
        text-transform: none;
    }
    input{
        background: #F5F5F5;
        border: none;
        color: black;
        font-weight: bold;
        font-size: 16px;
        &:focus{
            background: #F5F5F5;
            border: none;
            color: black;
            font-weight: bold;
            font-size: 16px;
        }
        &::placeholder{
            color: black;
        }
    }
    select{
        background: #F5F5F5;
        border: none;
        color: black;
        font-weight: bold;
        font-size: 16px;
        &:focus{
            background: #F5F5F5;
            border: none;
            color: black;
            font-weight: bold;
            font-size: 16px;
        }
    }
    textarea{
        background: #F5F5F5;
        border: none;
        color: black;
        font-size: 16px;
        
        &:focus{
            background: #F5F5F5;
            border: none;
            color: black;
            font-size: 16px;
        }
    }
}
.card-list{
    .product-img{
        height: 250px;
        img{
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
}
.cursor-p{
    cursor: pointer;
}
.bg-secondary{
    background: #E8E8E8 !important;
}
.addphoto{
    width: 148px;
    height: 148px;
    position: relative;
    margin-top: 15px;
    .close-b{
        position: absolute;
        right: -15px;
        top: -15px;
        z-index: 11;
        cursor: pointer;
        opacity: 1;
    }
    .mark-sec{
        padding: 10px;
        background: #00000063;
        position: absolute;
        bottom: 0;
        width: 100%;
        color: white;
    }
}
.outline-card{
    border: 1px solid $primary;
    border-radius: 4px;
    padding: 15px;
    a{
        color: black;
    }
}
.custom-tab{
    background: white;
    .nav-link{
        border-left: 0;
        border-right: 0;
        border-top: 0;
        font-size: 18px;
        color: black;
        font-weight: bold;
       padding: 10px 35px;
  
    }
    .nav-link.active {
        color: black;
        border-color: #dee2e6 #dee2e6 #fff;
        border-bottom: 3px solid red;

    }
}
.v-detail{
    height: 338px;
    border-radius: 8px;
    overflow: hidden;
    position: relative;
    cursor: pointer;

    img{
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
    .text-tag{
        position: absolute;
        top: 15px;
        left: 15px;
        background: #181A1E;
        font-size: 12px;
        color: white;
        padding: 5px 10px;
        border-radius: 4px;
    }
}

.form-check input[type="checkbox"], .form-check-radio input[type="radio"]{
    position: inherit;
  
}
.form-check .form-check-sign::before, .form-check .form-check-sign::after{
    color: $primary !important;
    font-size: 26px;
}
.text-muted{
    color: #9A9A9A;
}
.text-primary, .text-primary:hover{
    color: #F13903 !important;
}
.text-info, .text-info:hover{
    color: $info-color !important;
}
.text-success, .text-success:hover{
    color: $success-color !important;
}
.text-warning, .text-warning:hover{
    color: $warning-color !important;
}

.text-danger, .text-danger:hover{
    color: $danger-color !important;
}
